const data = [
  {
    img: '/static/img/profile-pic-l-2.jpg',
    title: 'Joisse Kaycee just sent a new comment!',
    date: '09.04.2018 - 12:45',
    id: 1
  },
  {
    img: '/static/img/notification-thumb.jpg',
    title: '1 item is out of stock!',
    date: '09.04.2018 - 12:45',
    id: 2
  },
  {
    img: '/static/img/notification-thumb-2.jpg',
    title: 'New order received! It is total $147,20.',
    date: '09.04.2018 - 12:45',
    id: 3
  },
  {
    img: '/static/img/notification-thumb-3.jpg',
    title: '3 items just added to wish list by a user!',
    date: '09.04.2018 - 12:45',
    id: 4
  }
]
export default data
