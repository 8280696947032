<template>
<!--<footer class="page-footer">
    <div class="footer-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-sm-6">
                    <p class="mb-0 text-muted">Sole Tracking 2023</p>
                </div>
            </div>
        </div>
    </div>
</footer>-->
</template>
<script>
export default {

}
</script>
