const data = [{
  id: "ui",
    icon: "simple-icon-map",
    label: "Mapa",
    to: "/plataforma/mapa",
    permissionLevelRequired:1
  },
  {
    id:"dispositivos",
    icon: "simple-icon-note",
    label: "Mis dispositivos",
    to: "/plataforma/dispositivos",
    permissionLevelRequired:2
  },
  {
    id:"device",
    icon: "simple-icon-feed",
    label: "Nuevo dispositivo",
    to: "/plataforma/dispositivo",
    permissionLevelRequired:2
  },
  {
    id:"store",
    icon: "iconsminds-shopping-cart",
    label: "Tienda",
    to: "/plataforma/tienda",
    permissionLevelRequired:3
  },
  {
    id:"otrosusuarios",
    icon: "iconsminds-male-female",
    label: "Usuarios",
    to: "/plataforma/usuarios",
    permissionLevelRequired:2
  },
  {
    id:"pannelmiddas",
    icon:"iconsminds-notepad",
    label:"Administrador",
    to:"/pannelmiddas",
    permissionLevelRequired:3,
    subs: [
      /*{
        id:"dashboard",
        icon:"iconsminds-home",
        to:"/panelmiddas/dashboard",
        label:"Inicio",
        permissionLevelRequired:3
      },*/
      {
        id:"dispositivos",
        icon:"iconsminds-control-2",
        to:"/panelmiddas/dispositivos",
        label:"Dispositivos",
        permissionLevelRequired:3
      },
      {
        id:"pedidos",
        icon:"iconsminds-envelope-2",
        to:"/panelmiddas/pedidos",
        label:"Pedidos",
        permissionLevelRequired:3
      },
      {
        id:"usuario",
        icon:"iconsminds-male-female",
        to:"/panelmiddas/usuarios",
        label:"Usuarios",
        permissionLevelRequired:3
      },
      {
        id:"planes",
        icon:"iconsminds-male-female",
        to:"/panelmiddas/planes",
        label:"Planes",
        permissionLevelRequired:3
      },
    ]
  }
];
export default data;
